<template>
  <div :class="$style.linkooklive">
    <LinkookLiveHeader></LinkookLiveHeader>
    <linkook-live-banner></linkook-live-banner>
    <linkook-live-tiles></linkook-live-tiles>
    <linkook-live-footer></linkook-live-footer>
  </div>
</template>
<script>
// @ is an alias to /src
import LinkookLiveHeader from '@/components/LinkookLiveHeader.vue'
import LinkookLiveBanner from '@/components/LinkookLiveBanner.vue'
import LinkookLiveTiles from '@/components/LinkookLiveTiles.vue'
import LinkookLiveFooter from '@/components/LinkookLiveFooter.vue'

import { ref, runTransaction } from "firebase/database";
import { rtdb } from "/firebaseConfig";

export default {
  name: 'LinkookLiveView',
  components: {
    LinkookLiveHeader,
    LinkookLiveBanner,
    LinkookLiveTiles,
    LinkookLiveFooter
  },
  data(){ 
    return {
      activeConnections: 0,
    };
  },
  methods: {
    recordVisitor() {
      const today = new Date().toISOString().split("T")[0]; // YYYY-MM-DD 형식
      const dailyVisitorRef = ref(rtdb, `visitorCount/${today}`);
      runTransaction(dailyVisitorRef, (currentValue) => {
        return (currentValue || 0) + 1;
      });
    },
  },
  mounted() {
    // 컴포넌트가 마운트될 때 방문자 수 증가
    this.recordVisitor();
  },
}
</script>
<style module>
.linkooklive {
  padding: 10px;
  width: 50%;
  margin: auto;
}

@media screen and (max-width: 1200px) {
.linkooklive {
  padding: 5px;
  width: 70%;
  margin: auto;
}
}

@media screen and (max-width: 800px) {
.linkooklive {
  padding: 5px;
  width: 95%;
  margin: auto;
}
}
</style>
